import React  from "react";

import { Row, Col, Modal, Button, Form, FormGroup, FormLabel } from "react-bootstrap"
import { postReq } from "../common/requests";
import { FormikTextField} from "../common/form-utils";
import * as yup from "yup"
import { Formik } from "formik"
import MiniCaseSearchPanel from "../common/mini-case-search";


const caseTaskSchema = yup.object.apply().shape({
  desc: yup.string().required("Describe the task"),
  caseID: yup.string().required("Needs a case to be linked to")
})

const FormCreator = (props) => {
  const { errors, touched, handleSubmit, handleChange, values, setFieldValue } = props.formikProps
  const args = {errors, touched, handleChange, values}
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add a task</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <FormGroup>
            <FormLabel>Description</FormLabel>
            <FormikTextField fieldName="desc" as="textarea" {...args} />
            <Form.Control.Feedback type="invalid">
              {errors.desc}
            </Form.Control.Feedback>
          </FormGroup>

          <FormGroup>
            <FormLabel>Selected Case</FormLabel>
            <Form.Control
              disabled
              name="caseID"
              value={values.caseID}
              isInvalid={touched.caseID && errors.caseID}
              isValid={touched.caseID && !errors.caseID}
            />
            <Form.Control.Feedback type="invalid">
              {errors.caseID}
            </Form.Control.Feedback>
            <Form.Control.Feedback>
              Ok
            </Form.Control.Feedback>
          </FormGroup>

          <FormGroup className="mt-3">
            <MiniCaseSearchPanel cases={props.cases} onChange={(id) => setFieldValue("caseID", id)} />
          </FormGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setShow(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

/**
 * Props:
 * - show
 * - setShow: should control `show`
 * - update: calls it with (tasks)
 * - cases: for searching
 */
export default function CaseTaskAddForm(props) {
  return (
    <Formik
      initialValues={{
        desc: "",
        caseID: ""
      }}
      validationSchema={caseTaskSchema}
      onSubmit={async (values, { resetForm }) => {
        let res = await postReq("task/add", {
          desc: values.desc,
          caseID: values.caseID
        })
        if (res.status === "success") {
          props.update(res.data.tasks)
          props.setShow(false)
          resetForm()
        } else {
          alert(res.message)
        }
      }}
    >
      {(formikProps) => (<FormCreator formikProps={formikProps} {...props} />)}
    </Formik>
  )
}