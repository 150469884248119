/**
 * Return flattened case object (static details only)
 * @param {*} c Case object
 * @returns 
 */
export const flattenCase = (c) => {
  return {
    petitioner: c.petitioner,
    respondent: c.respondent,
    retainedFor: c.retainedFor,
    caseNumCategory: c.caseNum.category,
    caseNumNum: c.caseNum.num,
    caseNumYear: c.caseNum.year,
    clientName: c.client.name,
    clientAddress: c.client.address,
    clientPhone: c.client.phone
  }
}

/**
 * Reverses the action of `flattenCase` 
 * @param {*} c Flattened case object
 * @returns 
 */
export const deflattenCase = (c) => {
  return {
    petitioner: c.petitioner,
    respondent: c.respondent,
    retainedFor: c.retainedFor,
    caseNum: {
      category: c.caseNumCategory,
      num: c.caseNumNum,
      year: c.caseNumYear
    },
    client: {
      name: c.clientName,
      address: c.clientAddress,
      phone: c.clientPhone
    }
  }
}

/**
 * Create case object (details only) having empty values
 * @returns Case details having empty values
 */
export const emptyCaseDetails = () => {
  return {
    petitioner: "",
    respondent: "",
    retainedFor: "petitioner",
    caseNum: {
      category: "",
      num: "",
      year: ""
    },
    client: {
      name: "",
      address: "",
      phone: ""
    }
  }
}