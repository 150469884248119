import React from "react";

import { Modal, Button, Form, FormGroup, FormLabel } from "react-bootstrap"
import { postReq } from "../common/requests";
import { FormikTextField} from "../common/form-utils";
import * as yup from "yup"
import { Formik } from "formik"

const listingSchema = yup.object.apply().shape({
  courtNum: yup.string(),
  itemNum: yup.string(),
  remarks: yup.string()
})

const FormCreator = (props) => {
  const { errors, touched, handleSubmit, handleChange, values } = props.formikProps
  const args = {errors, touched, handleChange, values}
  return (
    <Modal show={props.editing} onHide={() => props.setEditing(false)}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Edit listing for {props.listing.date}</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <FormGroup>
            <FormLabel>Court no.</FormLabel>
            <FormikTextField fieldName="courtNum" {...args} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Item no.</FormLabel>
            <FormikTextField fieldName="itemNum" {...args} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Remarks</FormLabel>
            <FormikTextField fieldName="remarks" as="textarea" {...args} />
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setEditing(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

/**
 * Props:
 * - listing (both id and date)
 * - editing
 * - setEditing
 * - update: calls it with (caseObj)
 */
export default function EditListingForm(props) {
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        id: props.listing.id,
        date: props.listing.date,
        courtNum: props.listing.courtNum,
        itemNum: props.listing.itemNum,
        remarks: props.listing.remarks.join("\n")
      }}
      validationSchema={listingSchema}
      onSubmit={async (values) => {
        let remarks = (values.remarks === "" ? [] : values.remarks.split("\n"))
        let res = await postReq("case/listing/update", {
          ...values,
          remarks: remarks
        })
        if (res.status === "success") {
          props.update(res.data.case)
          props.setEditing(false)
        } else {
          alert(res.message)
        }
      }}
    >
      {(formikProps) => (<FormCreator formikProps={formikProps} {...props} />)}
    </Formik>
  )
}