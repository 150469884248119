import React, { useState } from "react";
import {Table, Button} from "react-bootstrap";
import {postReq} from "../common/requests"
import { ConfirmModal } from "../common/common"

function StatusRow(props) {
  let isActive = props.isActive
  return (
    <Table>
      <tbody>
        <tr>
          <td>Status</td>
          <td className={"fw-bold " + (isActive ? "text-success" : "text-danger")}>
            {isActive ? "PENDING" : "DISPOSED"}
          </td>
          <td>
            <Button
              size="sm"
              variant="outline-dark"
              onClick={(e) => props.setEditing(true)}
            >
              {isActive ? "Dispose" : "Revive"}
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default function StatusPanel(props) {
  const [editing, setEditing] = useState(false)

  let caseObj = props.caseObj

  const handleConfirm = async () => {
    let res = await postReq("case/toggle", { id: caseObj.id })
    if (res.status === "success") {
      props.update(res.data.case)
      setEditing(false)
    } else {
      alert("Fail: " + res.message);
    }
  }

  if (!caseObj) return (<div>Loading...</div>)
  return (
    <div>
      <h4>Case: {caseObj.petitioner + " vs. " + caseObj.respondent}</h4>

      <StatusRow isActive={caseObj.isActive} setEditing={setEditing} />

      <ConfirmModal
        show={editing}
        onClose={() => setEditing(false)}
        onConfirm={handleConfirm}
        heading={"Confirm Action"}
        body={"Do you really want to " + (caseObj.isActive ? "dispose" : "revive") + " this case?"}
        actionName="Confirm"
      />
    </div>
  );
}