import React from "react";
import { postReq } from "../common/requests";
import {Table} from "react-bootstrap"
import EditListingForm from "../common/case-listing-edit";
import { ConfirmModal } from "../common/common";
import ListingRow from "./listing-row";

export default class ListingTable extends React.Component {
  constructor(props) {
    super(props);
    this.initState = {
      editing: false, // only one of these
      removing: false, // is true at a time
      lst: { date: "", courtNum: "", itemNum: "", remarks: [] }
    }
    this.state = this.initState;

    this.handleRemove = this.handleRemove.bind(this);
    this.setEditing = this.setEditing.bind(this);
  }

  setEditing(isEditing) {
    this.setState({editing: isEditing})
  }

  async handleRemove() {
    let res = await postReq("case/listing/remove", { id: this.props.caseObj.id, date: this.state.lst.date})
    if (res.status === "success") {
      this.props.update(res.data.case)
    } else {
      console.log(res)
      alert(res.message)
    }
    this.setState({removing: false})
  }

  render() {
    let c = this.props.caseObj
    if (!c) return (<div>Loading...</div>)

    let rows = c.listings.map((lst) =>
      <ListingRow
        key={lst.date}
        lst={lst}
        isActive={c.isActive}
        showEdit={() => this.setState({editing: true, lst: lst})}
        showRemove={() => this.setState({removing: true, lst: lst})}
      />
    );

    return (
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Court No.</th>
              <th>Item No.</th>
              <th>Remarks</th>
              <th>-</th>
            </tr>
          </thead>

          <tbody>
            {rows}
          </tbody>
        </Table>

        <EditListingForm
          listing={{id: c.id, ...this.state.lst}}
          editing={this.state.editing}
          setEditing={this.setEditing}
          update={this.props.update}
        />

        <ConfirmModal
          show={this.state.removing}
          onClose={() => this.setState({removing: false})}
          onConfirm={this.handleRemove}
          heading={"Confirm delete listing"}
          body="Do you really want to delete this listing"
          actionName="Delete"
        />
      </div>
    );
  }
}