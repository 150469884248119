import React from "react";
import {Button, Row, Col} from "react-bootstrap"
import moment from "moment";
import { getReq } from "../common/requests";
import AgendaTable from "./table";
import DatePickerForm from "../common/date-picker-form";
import { displayDate } from "../common/common";
import { generateAgendaDoc } from "./templater"
import categories from "./resources/categories.json"

export default class AgendaPage extends React.Component {
  constructor(props) {
    super(props)
    let d = this.props.location.date
    this.state = {
      date: d ? d : moment().format("YYYY-MM-DD"),
      agenda: null
    }
    this.loadAgenda = this.loadAgenda.bind(this)
  }

  componentDidMount() {
    this.loadAgenda(this.state.date)
  }

  async loadAgenda(date) {
    this.setState({date: date})
    let res = await getReq("agenda/find/" + date);
    if (res.status === "success") {
      this.setState({agenda: res.data.agenda})
    } else {
      alert("fail: " + res.message)
    }
  }

  render() {
    return (
      <Row className="mt-5">
        <Col>
        <h3>Cause List for {displayDate(this.state.date)}</h3>
        </Col>
        <Col>
        {
          this.state.agenda ? (
            <Button onClick={() => generateAgendaDoc(this.state.agenda, categories)}>
              Generate Doc
            </Button>
          ) : (
            <div>Loading... </div>
          )
        }
        </Col>
        <Col>
        <DatePickerForm
          initialDate={this.state.date}
          onSubmit={this.loadAgenda}
          buttonText={"Load"}
          isValid={(date) => {
            if (!date) return { valid: false, message: "Date is required" }
            if (!moment(date).isValid) return { valid: false, message: "Please enter a valid date" }
            let d = moment(date).format("YYYY-MM-DD")
            if (d === this.state.date) return { valid: false, message: "Already loaded for this date" }
            return { valid: true }
          }}
        />
        </Col>
        <AgendaTable agenda={this.state.agenda} loadAgenda={this.loadAgenda} categories={categories}/>
      </Row>
    )
  }
}