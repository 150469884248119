import React, { useState } from "react";
import {Row, Form} from "react-bootstrap"

export default function SearchPanel (props) {

  const [criteria, setCriteria] = useState("id")
  const [searchString, setSearchString] = useState("")

  return (
    <div>
      <Form.Group>
        <Form.Control
          value={searchString}
          placeholder="Enter search term"
          onChange={(e) => {
            setSearchString(e.target.value)
            props.updateTable(criteria, e.target.value)
          }}
        />
      </Form.Group>
      <Form.Group className="mt-2">
        {
          [
            ["id", "File No. / Court"],
            ["title", "Title"],
            ["caseNum", "Case No."]
          ].map(([value, label]) => (
            <Form.Check
              key={value}
              checked={criteria === value}
              label={label}
              type="radio"
              value={value}
              onChange={(e) => { setCriteria(e.target.value); props.updateTable(e.target.value, searchString) }}
            />
          ))
        }
      </Form.Group>

    </div>
  )
}