import { useState } from "react"
import { postReq } from "./requests"
import * as yup from "yup"
import {Formik} from "formik"
import { Modal, Container, Form, Button, Row, Col } from "react-bootstrap"
import { FormikTextField } from "./form-utils"

const addUserSchema = yup.object.apply().shape({
  username: yup.string().required("Required").matches(/^[a-z]{5,20}$/, "Username should be between 5-20 length, all lowercase letters"),
  fullName: yup.string().required("Required").min(5).max(20),
  password: yup.string().required("Required").min(8).max(64),
  confirmPassword: yup.string().required("Required").test("passwords-match", "Passwords must match", function(value) {
    return this.parent.password === value
  })
})

function FormCreator (props) {
  const { errors, touched, handleSubmit, handleChange, values, isSubmitting } = props.formikProps
  const args = {errors, touched, handleChange, values}
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <FormikTextField fieldName="username" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.username}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label>Full Name</Form.Label>
          <FormikTextField fieldName="fullName" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.fullName}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3 mb-3">
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <FormikTextField fieldName="password" type="password" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.password}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3 mb-3">
        <Form.Group>
          <Form.Label>Confirm Password</Form.Label>
          <FormikTextField fieldName="confirmPassword" type="password" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.confirmPassword}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Adding" : "Add"}
      </Button>
      {
        props.formError &&
        <div class="d-block invalid-feedback">
          {props.formError}
        </div>
      }
    </Form>
  )
}

/**
 * Props: 
 * - setUsers
 * - onSubmit : calls this function on submit (no args)
 */
function AddUserForm(props) {
  const [formError, setFormError] = useState(null)
  return (
      <Formik
        initialValues={{
          username: "",
          fullName: "",
          password: "",
          confirmPassword: ""
        }}
        validationSchema={addUserSchema}
        onSubmit={async (values) => {
          try {
            let res = await postReq("user/add", {
              username: values.username,
              fullName: values.fullName,
              password: values.password
            })
            if (res.status === "success") {
              props.setUsers(res.data.users)
              props.onSubmit()
            } else {
              setFormError(res.message)
            }

          } catch (error) {
            console.log(error)
          }
        }}
      >
      {(formikProps) => (<FormCreator formikProps={formikProps} formError={formError}/>)} 
      </Formik>
  )
}

/**
 * Props:
 * - show
 * - setShow
 * - setUsers
 */
export default function AddUserModal(props) {
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddUserForm setUsers={props.setUsers} onSubmit={() => props.setShow(false)}/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}