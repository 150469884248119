import { Table } from "react-bootstrap"

export default function CountersTable (props) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Court</th>
          <th>Offset</th>
          <th>Count</th>
        </tr>
      </thead>
      <tbody>
        {
          props.counters.map((c) => (
            <tr key={c.court}>
              <td>{c.court}</td>
              <td>{c.offset}</td>
              <td>{c.count}</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}