import React, { useState } from "react";
import { postReq } from "../common/requests";
import DatePickerForm from "../common/date-picker-form";
import moment from "moment";
import {Button, Modal } from "react-bootstrap"

/**
 * Props:
 * - caseID
 * - update: called w/o to indicate req. completion
 * - curDate: current date (for comparison, not letting user add past date)
 * - show: modal visibility
 * - setShow: modal visibility
 */
export default function UpdateListingModal(props) {

  async function addListing(date) {
    let body = {
      id: props.caseID,
      date: date,
      courtNum: "",
      itemNum: "",
      remarks: []
    }
    let res = await postReq("case/listing/add", body)
    if (res.status === "success") {
      props.update()
    } else {
      alert("fail: " + res.message)
    }
    props.setShow(false)
  }

  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add a task</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <DatePickerForm
            onSubmit={addListing}
            buttonText="Add Listing"
            initialDate={new Date()}
            isValid={(date) => {
              if (!date) return { valid: false, message: "Date is required" }
              if (!moment(date).isValid) return { valid: false, message: "Please enter a valid date" }
              let d = moment(date).format("YYYY-MM-DD")
              if (props.curDate.localeCompare(d) >= 0) return {
                valid: false,
                message: "Next date should be after current date"
              }
              return { valid: true }
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setShow(false)}>
            Close
          </Button>
        </Modal.Footer>
    </Modal>
  );
}