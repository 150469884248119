import React from "react";
import { postReq } from "./requests";

export default class LogoutPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      done: false
    }
  }

  async componentDidMount() {
    try {
      let res = await postReq("session/destroy", {}) 
      console.log(res)
      if (res.status === "success") {
        this.setState({done: true})
        this.props.clearUser()
      } else {
        alert("fail " + res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    if (this.state.done) {
      return (<div>Logged out</div>)
    } else {
      return (<div>Logging you out</div>)
    }
  }
}