import { useState } from "react"
import { postReq } from "./requests"
import * as yup from "yup"
import {Formik} from "formik"
import { Container, Form, Button, Row, Col } from "react-bootstrap"
import { FormikTextField } from "./form-utils"
import "./common.css"

/**
 * Schema for details
 * - flattened for simplicity
 * - revert for API request
 */
const loginSchema = yup.object.apply().shape({
  username: yup.string().required("Required").min(5).max(20),
  password: yup.string().required("Required").min(8).max(64)
})

function FormCreator (props) {
  const { errors, touched, handleSubmit, handleChange, values, isSubmitting } = props.formikProps
  const args = {errors, touched, handleChange, values}
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label>Username</Form.Label>
          <FormikTextField fieldName="username" {...args} />
        </Form.Group>
      </Row>
      <Row className="mt-3 mb-3">
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <FormikTextField fieldName="password" type="password" {...args} />
        </Form.Group>
      </Row>
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Logging in" : "Login"}
      </Button>
      {
        props.loginError &&
        <div class="d-block invalid-feedback">
          {props.loginError}
        </div>
      }
    </Form>
  )
}

function LoginForm(props) {
  let [loginError, setLoginError] = useState(null)

  return (
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={loginSchema}
        onSubmit={async (values) => {
          try {
          let res = await postReq("session/create", {
            username: values.username,
            password: values.password
          })
          console.log(res)
          if (res.status === "success") {
            props.setUser(res.data.user)
          } else {
            setLoginError("Username or password is incorrect")
          }
 
          } catch (error) {
            console.log(error)
          }
        }}
      >
      {(formikProps) => (<FormCreator formikProps={formikProps} loginError={loginError}/>)} 
      </Formik>
  )
}

export default function LoginPanel (props) {
  return (
    <div id="login-panel" className="card">
      <div className="card-body">
        <LoginForm {...props}/>
      </div>
    </div>
  )
}