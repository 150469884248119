import React from 'react';
import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import PizZipUtils from 'pizzip/utils/index.js';
import { saveAs } from 'file-saver';

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

/**
 * Generates output from template + data
 * @param {*} inputFile Template file with extension (.docx)
 * @param {*} data Data object for template
 * @param {*} outputFile Output file name with extension (.docx)
 */
export const generateDocument = (inputFile, data, outputFile) => {
  loadFile(process.env.PUBLIC_URL + "/" + inputFile, function (
    error,
    content
  ) {
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true
    });
    try {
      // render the document (replace all occurences of {first_name} by John, {last_name} by Doe, ...)
      doc.render(data);
    } catch (error) {
      // The error thrown here contains additional information when logged with JSON.stringify (it contains a properties object containing all suberrors).
      function replaceErrors(key, value) {
        if (value instanceof Error) {
          return Object.getOwnPropertyNames(value).reduce(function (
            error,
            key
          ) {
            error[key] = value[key];
            return error;
          },
            {});
        }
        return value;
      }
      console.log(JSON.stringify({ error: error }, replaceErrors));

      if (error.properties && error.properties.errors instanceof Array) {
        const errorMessages = error.properties.errors
          .map(function (error) {
            return error.properties.explanation;
          })
          .join('\n');
        console.log('errorMessages', errorMessages);
        // errorMessages is a humanly readable message looking like this:
        // 'The tag beginning with "foobar" is unopened'
      }
      throw error;
    }
    const out = doc.getZip().generate({
      type: 'blob',
      mimeType:
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    }); //Output the document using Data-URI
    saveAs(out, outputFile);
  });
};

/**
 * Generate docx for an agenda
 * Args:
 * - agenda
 */
export function generateAgendaDoc(agenda, categories) {
  let items = agenda.items
  let categoryItems = categories.map(c => ({
    name: c.name.toUpperCase(),
    items: items
      .filter(item => c.courts.includes(item.id.split("-")[0]))
      .map((e, i) => ({index: i + 1, ...e}))
  })).filter(c => c.items.length > 0)
  let data = {
    date: agenda.date,
    categories: categoryItems
  }
  console.log(JSON.stringify(data, null, 2))
  generateDocument(
    "causelist-template.docx",
    data,
    "causelist-" + agenda.date + ".docx"
  )
}