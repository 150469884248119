import React from "react";

import { Row, Col, Button, Form, FormGroup, FormLabel } from "react-bootstrap"
import { postReq } from "../common/requests";
import { FormikRadioGroup, FormikTextField} from "../common/form-utils";
import * as yup from "yup"
import { Formik } from "formik"
import { deflattenCase, flattenCase } from "../common/case-utils";

/**
 * Schema for details
 * - flattened for simplicity
 * - revert for API request
 */
const detailSchema = yup.object.apply().shape({
  petitioner: yup.string().required("Required"),
  respondent: yup.string().required("Required"),
  retainedFor: yup.string()
    .oneOf(["petitioner", "respondent", "other"])
    .required("Required"),
  caseNumCategory: yup.string(),
  caseNumNum: yup.string(),
  caseNumYear: yup.string(),
  clientName: yup.string(),
  clientAddress: yup.string(),
  clientPhone: yup.string()
})

const FormCreator = (props) => {
  const { errors, touched, handleSubmit, handleChange, values } = props.formikProps
  const args = {errors, touched, handleChange, values}
  return (
    <Form noValidate onSubmit={handleSubmit}>

      <FormGroup as={Row} className="mt-3">
        <Col sm={3}>
        <FormLabel>Petitioner</FormLabel>
        </Col>
        <Col>
        <FormikTextField fieldName="petitioner" {...args} />
        <Form.Control.Feedback type="invalid">
          {errors.petitioner}
        </Form.Control.Feedback>
        </Col>
      </FormGroup>

      <FormGroup as={Row} className="mt-3">
        <Col sm={3}>
          <FormLabel>Respondent</FormLabel>
        </Col>
        <Col>
        <FormikTextField fieldName="respondent" {...args} />
        <Form.Control.Feedback type="invalid">
          {errors.respondent}
        </Form.Control.Feedback>
        </Col>
      </FormGroup>

      <Row className="mt-3">
        <FormGroup as={Col} sm={3}>
          <FormLabel>Retained For</FormLabel>
        </FormGroup>
        <FormGroup as={Col}>
          <Col>
            <FormikRadioGroup
              fieldName="retainedFor"
              options={[
                ["Petitioner", "petitioner"],
                ["Respondent", "respondent"],
                ["Other", "other"]]
              }
              {...args}
            />
          </Col>
        </FormGroup>
      </Row>

      <Row className="mt-3">
        <FormGroup as={Col} sm={3}>
          <FormLabel>Case No. </FormLabel>
        </FormGroup>
        <FormGroup as={Col} sm={3}>
          <FormikTextField fieldName="caseNumCategory" placeholder="Type" {...args} />
        </FormGroup>
        <FormGroup as={Col} sm={3}>
          <FormikTextField fieldName="caseNumNum" placeholder="Number" {...args} />
        </FormGroup>
        <FormGroup as={Col} sm={3}>
          <FormikTextField fieldName="caseNumYear" placeholder="Year" {...args} />
        </FormGroup>
      </Row>

      <Row className="mt-3 mb-3">
        <FormGroup as={Col} sm={3}>
          <FormLabel>Client details</FormLabel>
        </FormGroup>
        <FormGroup as={Col} sm={3}>
          <FormikTextField fieldName="clientName" placeholder="Name" {...args} />
        </FormGroup>
        <FormGroup as={Col} sm={3}>
          <FormikTextField fieldName="clientPhone" placeholder="Phone"  {...args} />
        </FormGroup>
        <FormGroup as={Col} sm={3}>
          <FormikTextField fieldName="clientAddress" placeholder="Address"  {...args} />
        </FormGroup>
      </Row>
      <Button type="submit">Save</Button>
    </Form>
  )
}

/**
 * Props:
 * caseObj
 * setEditing
 * update
 */
export default function EditForm(props) {
  let c = props.caseObj
  return (
    <div>
      <div className="fw-bold">Editing details</div>
      <Formik
        initialValues={flattenCase(c)}
        validationSchema={detailSchema}
        onSubmit={async (values) => {
          let body = { id: c.id, ...deflattenCase(values)}
          let res = await postReq("case/update", body)
          if (res.status === "success") {
            props.update(res.data.case)
            props.setEditing(false)
          } else {
            alert(res.message)
          }
        }}
      >
      {(formikProps) => (<FormCreator formikProps={formikProps}/>)}
      </Formik>
    </div>
  )
}