import React from "react";

import { Row, Col, Button, Form, FormGroup, FormLabel } from "react-bootstrap"
import { postReq } from "../common/requests";
import { FormikRadioGroup, FormikTextField, FormikSelect} from "../common/form-utils";
import * as yup from "yup"
import { Formik } from "formik"
import { deflattenCase, emptyCaseDetails, flattenCase } from "../common/case-utils";

/**
 * Schema for details
 * - flattened for simplicity
 * - revert for API request
 */
const detailSchema = yup.object.apply().shape({
  court: yup.string().required("Required"),
  seqNum: yup.string().required("Required"),
  petitioner: yup.string().required("Required"),
  respondent: yup.string().required("Required"),
  retainedFor: yup.string()
    .oneOf(["petitioner", "respondent", "other"])
    .required("Required"),
  caseNumCategory: yup.string(),
  caseNumNum: yup.string(),
  caseNumYear: yup.string(),
  clientName: yup.string(),
  clientAddress: yup.string(),
  clientPhone: yup.string()
})

const FormCreator = (props) => {
  const { errors, touched, handleSubmit, handleChange, values, setFieldValue } = props.formikProps
  const args = {errors, touched, handleChange, values}
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Row className="mt-3">
        <FormGroup as={Col} sm={2}>
          <FormLabel>Court</FormLabel>
          <FormikSelect
            fieldName="court"
            options={
              props.counters.map(c => [c.court, c.court])
            }
            {...args}
            handleChange={(e) => {
              const { value } = e.target
              let counter = props.counters.find(c => c.court === value)
              setFieldValue("court", value)
              setFieldValue("seqNum", counter.offset + counter.count + 1)
            }}
          />
          <Form.Control.Feedback type="invalid">
            {errors.court}
          </Form.Control.Feedback>
        </FormGroup>

        <FormGroup as={Col} sm={2}>
          <FormLabel>S. No.</FormLabel>
          <Form.Control name="seqNum" disabled value={values.seqNum} />
        </FormGroup>
      </Row>

        <FormGroup as={Row} className="mt-3">
          <Col sm={2}>
          <FormLabel>Petitioner</FormLabel>
          </Col>
          <Col sm={6}>
          <FormikTextField fieldName="petitioner" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.petitioner}
          </Form.Control.Feedback>
          </Col>
        </FormGroup>

        <FormGroup as={Row} className="mt-3">
          <Col sm={2}>
          <FormLabel>Respondent</FormLabel>
          </Col>
          <Col sm={6}>
          <FormikTextField fieldName="respondent" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.respondent}
          </Form.Control.Feedback>
          </Col>
        </FormGroup>

      <FormGroup as={Row} className="mt-3">
        <Col sm={2}>
          <FormLabel>Retained For</FormLabel>
        </Col>
          <Col>
            <FormikRadioGroup
              fieldName="retainedFor"
              options={[
                ["Petitioner", "petitioner"],
                ["Respondent", "respondent"],
                ["Other", "other"]]
              }
              {...args}
            />
          </Col>
      </FormGroup>


      <FormGroup as={Row} className="mt-3">
        <Col sm={2}>
          <FormLabel>Case No. </FormLabel>
        </Col>
        <Col sm={2}>
          <FormikTextField fieldName="caseNumCategory" placeholder="Type" {...args} />
        </Col>
        <Col sm={2}>
          <FormikTextField fieldName="caseNumNum" placeholder="Number" {...args} />
        </Col>
        <Col sm={2}>
          <FormikTextField fieldName="caseNumYear" placeholder="Year" {...args} />
        </Col>
      </FormGroup>

      <FormGroup as={Row} className="mt-3 mb-3">
        <Col sm={2}>
          <FormLabel>Client details</FormLabel>
        </Col>
        <Col sm={2}>
          <FormikTextField fieldName="clientName" placeholder="Name" {...args} />
        </Col>
        <Col sm={2}>
          <FormikTextField fieldName="clientPhone" placeholder="Phone"  {...args} />
        </Col>
        <Col sm={2}>
          <FormikTextField fieldName="clientAddress" placeholder="Address"  {...args} />
        </Col>
      </FormGroup>
      <Button type="submit">Add case</Button>
    </Form>
  )
}

/**
 * Props:
 * addCase
 * counters
 */
export default function AddCaseForm(props) {
  let c = emptyCaseDetails()
  return (
    <div>
      <Formik
        initialValues={{
          court: "",
          seqNum: "",
          ...flattenCase(c)
        }}
        validationSchema={detailSchema}
        onSubmit={async (values) => {
          let {court, seqNum, ...body} = {...values}
          body = deflattenCase(body)
          body.id = court + "-" + seqNum
          props.addCase(body)
        }}
      >
      {(formikProps) => (<FormCreator formikProps={formikProps} counters={props.counters}/>)}
      </Formik>
    </div>
  )
}