import React from "react"
import {Link} from "react-router-dom"
import {Table} from "react-bootstrap"
/**
 * props:
 * - caseObj: case partial object
 * - index: row index in table
 */
const CaseRow = (props) => {
  let c = props.caseObj;
  console.log(c)
  return (
    <tr>
      <td>{props.index}</td>
      <td>{c.id}</td>
      <td>{c.caseNum}</td>
      <td><Link to={"/case/get/" + c.id}>{c.title}</Link></td>
      <td className={"fw-bold " + (c.isActive ? "text-success" : "text-danger")}>
        {c.isActive ? "PENDING" : "DISPOSED"}
      </td>
    </tr>
  );
}

/**
 * props: cases, searchDone
 */
export default class ResultTable extends React.Component {
  render() {
    let cases = this.props.cases;
    let result =
      <tr>
        <td colSpan="4" className="lead">
          {this.props.searchDone
            ? "No cases found"
            : "Search results will appear here"
          }
        </td>
      </tr>
    
    let rows = this.props.cases.map(
      (c, i) => <CaseRow index={i+1} key={c.id} caseObj={c} />
    );

    return (
      <Table>
        <thead>
          <tr>
            <th>#</th>
            <th>File No.</th>
            <th>Case No.</th>
            <th>Title</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {cases.length > 0 ? rows : result}
        </tbody>
      </Table>
    );
  }
}