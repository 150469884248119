import React from "react"
import { getReq, postReq } from "./requests"
import { Button, Row, Table } from "react-bootstrap"
import AddUserModal from "./add-user-modal"
import { ConfirmModal } from "./common"

export default class UserPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      showAddModal: false,
      showRemoveModal: false,
      selectedUser: null
    }

    this.removeUser = this.removeUser.bind(this)
  }

  async componentDidMount() {
    try {
      let res = await getReq("user/get")
      if (res.status === "success") {
        this.setState({users: res.data.users})
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async removeUser() {
    try {
      let res = await postReq("user/remove", {
        username: this.state.selectedUser
      })
      if (res.status === "success") {
        this.setState({users: res.data.users, showRemoveModal: false})
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    return (
      <div className="mt-5">
        <Button onClick={() => this.setState({showAddModal: true})}>New user</Button>
        <Row className="mt-5">
          <UsersTable 
            users={this.state.users}
            removeUser={(user) => this.setState({selectedUser: user, showRemoveModal: true})}
          />
        </Row>
        <ConfirmModal
          show={this.state.showRemoveModal}
          onClose={() => this.setState({showRemoveModal: false})}
          onConfirm={() => this.removeUser()}
          heading={"Confirm action: delete user"}
          body={"Do you really want to delete user " + this.state.selectedUser}
          actionName="Yes"
        />
        <AddUserModal
          show={this.state.showAddModal}
          setShow={(x) => this.setState({showAddModal: x})}
          setUsers={(u) => this.setState({users: u})}
        />
      </div>
    )
  }
}

function UsersTable (props) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Username</th>
          <th>Full Name</th>
          <th>Elevated user?</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          props.users.map((user) => (
            <tr key={user.username}>
              <td>{user.username}</td>
              <td>{user.fullName}</td>
              <td>{user.isElevated ? "YES" : "NO"}</td>
              <td>
              {!user.isElevated && 
                <Button
                  onClick={() => props.removeUser(user.username)}
                >Delete
                </Button>
              }</td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}