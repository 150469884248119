import { useState } from "react"
import { postReq } from "../common/requests"
import * as yup from "yup"
import {Formik} from "formik"
import { Modal, Container, Form, Button, Row, Col } from "react-bootstrap"
import { FormikTextField } from "../common/form-utils"

const counterSchema = yup.object.apply().shape({
  court: yup.string().required("Required").matches(/^[A-Z]{2,4}$/, "Court name should be uppercase, between 2-4 letters"),
  confirmCourt: yup.string().required("Required").test("courts-match", "Court names must match", function(value) {
    return this.parent.court === value
  }),
  offset: yup.number()
  .required("Required")
  .integer("should be an integer")
  .positive("should be positive")
  .lessThan(10000, "should be < 10000")
})

function FormCreator (props) {
  const { errors, touched, handleSubmit, handleChange, values, isSubmitting } = props.formikProps
  const args = {errors, touched, handleChange, values}
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label>Court</Form.Label>
          <FormikTextField fieldName="court" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.court}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3">
        <Form.Group>
          <Form.Label>Confirm court</Form.Label>
          <FormikTextField fieldName="confirmCourt" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.confirmCourt}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mt-3 mb-3">
        <Form.Group>
          <Form.Label>Offset</Form.Label>
          <FormikTextField fieldName="offset" {...args} />
          <Form.Control.Feedback type="invalid">
            {errors.offset}
          </Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? "Adding" : "Add"}
      </Button>
      {
        props.formError &&
        <div className="d-block invalid-feedback">
          {props.formError}
        </div>
      }
    </Form>
  )
}

/**
 * Props: 
 * - addCounter
 * - onSubmit : calls this function on submit (no args)
 */
function AddCounterForm(props) {
  const [formError, setFormError] = useState(null)
  return (
      <Formik
        initialValues={{
          court: "",
          confirmCourt: "",
          offset: 0
        }}
        validationSchema={counterSchema}
        onSubmit={async (values) => {
          try {
            let res = await postReq("counter/add", {
              court: values.court,
              offset: parseInt(values.offset)
            })
            if (res.status === "success") {
              console.log(res)
              props.addCounter(res.data.counter)
              props.onSubmit()
            } else {
              setFormError(res.message)
            }

          } catch (error) {
            console.log(error)
          }
        }}
      >
      {(formikProps) => (<FormCreator formikProps={formikProps} formError={formError}/>)} 
      </Formik>
  )
}

/**
 * Props:
 * - show
 * - setShow
 * - addCounter
 */
export default function AddCounterModal(props) {
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Add counter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddCounterForm addCounter={props.addCounter} onSubmit={() => props.setShow(false)}/>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => props.setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}