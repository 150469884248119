import React from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import { postReq, getReq } from "../common/requests";
import {ConfirmModal} from "../common/common"
import CaseTaskAddForm from "./case-task-add";
import CaseTaskRow from "./case-task-row";

export default class CaseTaskPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      removing: false,
      adding: false,
      task: { id: "", desc: "", isDone: false },
      cases: [] // for search
    }
    this.toggle = this.toggle.bind(this)
    this.remove = this.remove.bind(this)
    this.loadCases = this.loadCases.bind(this)
  }

  async loadCases() {
    try {
      let res = await getReq("case/find/by")
      if (res.status === "success") {
        this.setState({cases: res.data.cases})
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async toggle(id) {
    try {
      let res = await postReq("task/toggle", { id: id })
      if (res.status === "success") {
        this.props.updateTasks(res.data.tasks)
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async remove() {
    let id = this.state.task.id
    try {
      let res = await postReq("task/remove", { id: id })
      if (res.status === "success") {
        this.props.updateTasks(res.data.tasks)
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
    this.setState({removing: false})
  }

  render() {
    let tasks = this.props.tasks

    let rows = tasks.map(t => (
      <CaseTaskRow
        key={t.id}
        task={t}
        remove={() => this.setState({removing: true, task: t})}
        toggle={() => this.toggle(t.id)}
      />
    ))

    return (
      <div>
        <Row>
          <Col><h4>Case Tasks</h4></Col>
          <Col>
            <Button
              variant="outline-primary"
              onClick={() => { this.loadCases(); this.setState({ adding: true })}}
            >
              Add task
            </Button>
          </Col>
        </Row>
        <Table responsive>

          <thead>
            <tr>
              <th>Case</th>
              <th>Desc.</th>
              <th></th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {rows}
          </tbody>

        </Table>
        <ConfirmModal
          show={this.state.removing}
          onClose={() => this.setState({ removing: false })}
          onConfirm={this.remove}
          heading={"Confirm delete task"}
          body={"Do you really want to delete task related to " + this.state.task.caseID + ": " + this.state.task.desc}
          actionName="Delete"
        />

        <CaseTaskAddForm
          show={this.state.adding}
          setShow={(x) => this.setState({ adding: x })}
          update={this.props.updateTasks}
          cases={this.state.cases}
        />
      </div >
    )
  }
}