import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResultTable from './result-table';
import SearchPanel from './search-panel';
import { getReq } from '../common/requests';
import { escapeRegex } from '../common/common';

export default class CaseSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      cases: [],
      searchDone: false, // to show placeholder on table
      filteredCases: []
    }
    this.updateTable = this.updateTable.bind(this);
  }

  async componentDidMount() {
    try {
      let res = await getReq("case/find/by")
      if (res.status === "success") {
        this.setState({loaded: true, cases: res.data.cases})
      }
    } catch (error) {
      console.log(error)
    }
  }

  updateTable(prop, value) {
    value = value.trim()
    if (value === "") { // do not show cases on empty values
      this.setState({
        searchDone: false,
        filteredCases: []
      })
    } else {
      value = escapeRegex(value)
      let filteredCases = this.state.cases
        .filter(c => {
          let r = (
            prop === "id"
            ? new RegExp(`^${value}|${value}$`, "i")
            : new RegExp(value, "i")
          )
          return c[prop].match(r)
        })

      this.setState({
        searchDone: true,
        filteredCases: filteredCases
      })
    }
  }

  render() {
    return (
      <div>
        <Row className="mt-5">
          <h1>Search cases</h1>
        </Row>
        <Row className="mt-2">
          <Col>
            <SearchPanel updateTable={this.updateTable} />
          </Col>
        </Row>  
        <Row className="mt-5">
          <Col>
            <ResultTable searchDone={this.state.searchDone} cases={this.state.filteredCases}/>
          </Col>
        </Row>  
      </div>
    );
  }
}