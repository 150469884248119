import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getReq } from "../common/requests"
import DetailsTable from "./details-table"
import StatusPanel from "./status-panel"
import ListingTable from "./listing-table"
import AddListingPanel from "./add-listing-panel"

export default class CasePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caseObj: null
    };
    this.update = this.update.bind(this);
  }
  
  async componentDidMount() {
    try {
      var result = await getReq("case/find/id/" + this.props.id)
      if (result.status === "success") {
        this.setState({
          caseObj: result.data.case
        })
      } else {
        alert("fail: " + result.message)
      }
    } catch(error) {
      console.log(error)
    }
  }

  update(caseObj) {
    this.setState({caseObj: caseObj})
  }
  
  render() {
    let props = {
      caseObj: this.state.caseObj,
      update: this.update
    }
    return (
      <Row className="mt-5">
        <Col>
          <StatusPanel {...props} />
          <DetailsTable {...props} />
        </Col>
        <Col>
          <AddListingPanel {...props} />
          <ListingTable {...props} />
        </Col>
      </Row>
    );
  }
}