import React from "react"
import { Table, Row, Col, Button } from "react-bootstrap"
import { getReq, postReq } from "./requests"
import { ConfirmModal } from "./common"
import moment from "moment"
import "../index.css"

export default class LogsPanel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoaded: false,
      showModal: false,
      logs: null
    }

    this.clearLogs = this.clearLogs.bind(this)
  }

  async componentDidMount() {
    try {
      let res = await getReq("logs/get")
      if (res.status === "success") {
        this.setState({logs: res.data.logs.reverse(), isLoaded: true})
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async clearLogs() {
    try {
      let res = await postReq("logs/clear", {})
      if (res.status === "success") {
        this.setState({logs: [], showModal: false})
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    if (!this.state.isLoaded) return (<div>Loading...</div>)
    return (
      <div className="mt-5">
        <Row>

        <Col><Button onClick={() => this.setState({showModal: true})}>Clear logs</Button></Col>
        <Col>Total: {this.state.logs.length}</Col>
        </Row>
        <Row className="mt-5">
          <LogsTable logs={this.state.logs} />
        </Row>
        <ConfirmModal
          show={this.state.showModal}
          onClose={() => this.setState({showModal: false})}
          onConfirm={this.clearLogs}
          heading={"Confirm action: clear logs?"}
          body="Do you really want to clear logs"
          actionName="Yes"
        />
      </div>
    )
  }
}

function LogsTable (props) {
  return (
    <Table>
      <thead>
        <tr>
          <th>Time</th>
          <th>User</th>
          <th>Path</th>
          <th>Content</th>
        </tr>
      </thead>
      <tbody>
        {
          props.logs.map((log) => (
            <tr key={log.time}>
              <td>{moment(log.time).format("dddd, MMMM Do YYYY, h:mm:ss a")}</td>
              <td>{log.user}</td>
              <td>{log.path}</td>
              <td><code>{log.content}</code></td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  )
}