import React, {useState} from "react";

import { Table, Button, Row, Col } from "react-bootstrap"
import EditForm from "./details-table-form"

/**
 * Props:
 * - caseObj
 * - update
 */
export default function DetailsTable(props) {

  const [editing, setEditing] = useState(false)

  let c = props.caseObj
  if (!c) return (<div>Loading...</div>)
  return editing 
  ? (<EditForm setEditing={setEditing} {...props}/>)
  : (<StaticDetails setEditing={setEditing} caseObj={props.caseObj}/>)
}

/**
 * Shows all static details
 * On clicking editing button, shows edit form
 * Props:
 * - caseObj
 * - setEditing 
 */
function StaticDetails(props) {
  let c = props.caseObj
  return (
    <Table>
      <tbody>
      <tr>
        <td>Petitioner</td>
        <td>{c.petitioner}</td>
      </tr>
      <tr>
        <td>Respondent</td>
        <td>{c.respondent}</td>
      </tr>
      <tr>
        <td>Retained for</td>
        <td>{c.retainedFor}</td>
      </tr>
      <tr>
        <td>Case No.</td>
        <td>{c.caseNum.category + " - " + c.caseNum.num + " / " + c.caseNum.year}</td>
      </tr>
      <tr>
        <td>Client</td>
        <td>
          <div>Name: {c.client.name}</div>
          <div>Phone: {c.client.phone}</div>
          <div>Address: {c.client.address}</div>
        </td>
      </tr>
      <tr>

      <td>
      <Button onClick={(e) => props.setEditing(true)}>Edit details</Button>
      </td>
      </tr>
      </tbody>
    </Table>
  )
}