import React from "react";
import {Table} from "react-bootstrap"
import {postReq} from "../common/requests"
import AgendaRow from "./row";
import EditListingForm from "../common/case-listing-edit";
import { ConfirmModal } from "../common/common";
import UpdateListingModal from "./update-listing-modal";

/**
 * Very similar to ListingTable on case page
 * Props:
 * - agenda
 * - loadAgenda
 */
export default class AgendaTable extends React.Component {
  constructor(props) {
    super(props);
    this.initState = {
      editing: false, // only one of these
      removing: false, // is true at a time
      addingListing: false,
      item: { id: "", courtNum: "", itemNum: "", remarks: [] }
    }
    this.state = this.initState;

    this.handleRemove = this.handleRemove.bind(this);
    this.setEditing = this.setEditing.bind(this);
    this.getCategorizedRows = this.getCategorizedRows.bind(this)
  }

  setEditing(isEditing) {
    this.setState({editing: isEditing})
  }

  async handleRemove() {
    let res = await postReq("case/listing/remove",
      {
        id: this.state.item.id,
        date: this.props.agenda.date
      }
    )
    if (res.status === "success") {
      this.props.loadAgenda(this.props.agenda.date)
      this.setState({removing: false})
    } else {
      console.log(res)
      alert(res.message)
    }
    this.setState({removing: false})
  }

  getCategorizedRows(items) {
    let categoryItems = this.props.categories.map(c => ({
      category: c,
      items: items.filter(item => c.courts.includes(item.id.split("-")[0]))
    }))

    let rawRows = categoryItems.flatMap(c => (
      [
        { isItem: false, item: { numCases: c.items.length, ...c.category } },
        ...c.items.map(e => ({ isItem: true, item: e }))
      ]
    ))

    return rawRows.map((rawRow) => {
      let item = rawRow.item
      if (rawRow.isItem) return (
        <AgendaRow
          item={item}
          key={item.id}
          showEdit={() => this.setState({ editing: true, item: item })}
          showRemove={() => this.setState({ removing: true, item: item })}
          showAddListing={() => this.setState({ addingListing: true, item: item})}
        />
      )
      return (
        <tr className="table-secondary" key={item.name}>
          <td colSpan="8">{item.name + " - " + item.numCases + " case(s)"}</td>
        </tr>
      )
    });
  }

  render() {
    let ag = this.props.agenda
    if (!ag) return (<div>Loading...</div>)

    return (
      <div>
        <Table responsive>
          <thead>
            <tr>
              <th>File No.</th>
              <th>Title</th>
              <th>Case No.</th>
              <th>Court No.</th>
              <th>Item No.</th>
              <th>Remarks</th>
              <th>Next date</th>
              <th>-</th>
            </tr>
          </thead>

          <tbody>
            {this.getCategorizedRows(ag.items)}
          </tbody>
        </Table>

        <EditListingForm
          listing={{date: this.props.agenda.date, ...this.state.item}}
          editing={this.state.editing}
          setEditing={this.setEditing}
          update={() => this.props.loadAgenda(ag.date)}
        />

        <ConfirmModal
          show={this.state.removing}
          onClose={() => this.setState({removing: false})}
          onConfirm={this.handleRemove}
          heading={"Confirm delete listing"}
          body="Do you really want to delete this listing"
          actionName="Delete"
        />

        <UpdateListingModal
          caseID={this.state.item.id}
          update={() => this.props.loadAgenda(ag.date)}
          curDate={ag.date}
          show={this.state.addingListing}
          setShow={(x) => this.setState({addingListing: x})}
        />
      </div>
   )
  }
}