import React, {useState} from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
  useParams,
  NavLink
} from "react-router-dom";

import {LinkContainer} from "react-router-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css';

import {NavDropdown, Nav, Container, Navbar} from "react-bootstrap";

import CaseSearch from "./search/main"
import AgendaPage from "./agenda/main";
import CasePage from "./case/main"
import HomePage from "./home/main"
import AddCasePanel from "./add-case/main";
import LoginPanel from "./common/login-page";
import LogoutPanel from "./common/logout-page";
import { getReq } from "./common/requests";
import LogsPanel from "./common/logs-panel";
import UsersPanel from "./common/users-panel";
import CounterPanel from "./counters/main";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isLoaded: false
    }
  }

  async componentDidMount() {
    // check login status here
    try {
      let res = await getReq("session/verify")
      if (res.status === "success") {
        this.setState({ user: res.data.user })
      }
      this.setState({isLoaded: true})
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    if (!this.state.isLoaded) return (<div>Loading...</div>);
    if (!this.state.user) return (
      <Router>
        <Route path="/login">
          <LoginPanel setUser={(user) => this.setState({ user: user })} />
        </Route>
        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Router>
    )
    return (
      <Router>
        <div>
          <Navbar bg="dark" variant="dark">
            <Container>
              <Navbar.Brand>Case Diary <span className="text-muted">beta</span></Navbar.Brand>
              <Nav className="me-auto">
                <Nav.Item>
                  <NavLink exact className="nav-link" to="/">Home</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink exact className="nav-link" to="/search">Search</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink exact className="nav-link" to="/causelist">Cause List</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink exact className="nav-link" to="/case/add">Add Case</NavLink>
                </Nav.Item>
                { 
                this.state.user.isElevated &&
                <Nav.Item>
                  <NavLink exact className="nav-link" to="/manage/logs">Logs</NavLink>
                </Nav.Item>
                }
                { 
                this.state.user.isElevated &&
                <Nav.Item>
                  <NavLink exact className="nav-link" to="/manage/users">Users</NavLink>
                </Nav.Item>
                }
                { 
                this.state.user.isElevated &&
                <Nav.Item>
                  <NavLink exact className="nav-link" to="/manage/counters">Counters</NavLink>
                </Nav.Item>
                }
              </Nav>
              <Nav>
                <Navbar.Text>Logged in as</Navbar.Text>
                <NavDropdown title={this.state.user.username}>
                  <NavLink exact className="dropdown-item" to="/logout">Log out</NavLink>
                </NavDropdown>
              </Nav>
            </Container>
          </Navbar>

          <Container>
            <Switch>
              { 
              this.state.user.isElevated &&
              <Route exact path="/manage/logs">
                <LogsPanel />
              </Route>
              }
              { 
              this.state.user.isElevated &&
              <Route exact path="/manage/users">
                <UsersPanel />
              </Route>
              }
              { 
              this.state.user.isElevated &&
              <Route exact path="/manage/counters">
                <CounterPanel />
              </Route>
              }
              <Route exact path="/logout">
                <LogoutPanel clearUser={() => this.setState({user: null})}/>
              </Route>
              <Route exact path="/case/get/:id">
                <CasePageLoader />
              </Route>
              <Route exact path="/case/add">
                <AddCasePanel />
              </Route>
              <Route exact path="/search">
                <CaseSearch />
              </Route>
              <Route exact path="/causelist/:date">
                <AgendaLoader />
              </Route>
              <Route
                path="/causelist"
                render={(props) => <AgendaPage {...props} />}
              />
              <Route exact path="/">
                <HomePage />
              </Route>
              <Route path="/">
                <Redirect to="/" />
              </Route>
            </Switch>
          </Container>
        </div>
      </Router>
    );
  }
}

function CasePageLoader() {
  let { id } = useParams();
  return <CasePage id={id}/>
}

function AgendaLoader() {
  let { date } = useParams();
  return <Redirect to={{pathname: "/causelist", date: date}}/>
}