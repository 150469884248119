import React from "react";
import CountersTable from "./counters-table";
import { getReq } from "../common/requests";
import { Button, Row} from "react-bootstrap"
import AddCounterModal from "./add-counter-modal";

export default class CounterPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      counters: [],
      showAddModal: false,
    }
    this.addCounter = this.addCounter.bind(this)
  }

  async componentDidMount() {
    try {
      let res = await getReq("counter/find")
      if (res.status === "success") {
        this.setState({counters: res.data.counters})
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  addCounter (c) {
    let counters = [...this.state.counters]
    counters.push(c)
    this.setState({counters: counters})
  }

  render() {
    return (
      <div className="mt-5">
        <Button onClick={() => this.setState({showAddModal: true})}>New counter</Button>
        <Row className="mt-5">
          <CountersTable counters={this.state.counters} />
        </Row>
        <AddCounterModal
          show={this.state.showAddModal}
          setShow={(x) => this.setState({showAddModal: x})}
          addCounter={this.addCounter}
        />
      </div>
    )
  }
}