import React from 'react';
import {Link} from "react-router-dom";
import { postReq, getReq } from "../common/requests"
import AddCaseForm from './add-case-form';

export default class AddCasePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      counters: null,
      caseID: null
    };
    this.addCase = this.addCase.bind(this);
    this.loadCounters = this.loadCounters.bind(this);
  }
  
  async componentDidMount() {
    this.loadCounters()
  }

  async loadCounters() {
    try {
      var result = await getReq("counter/find")
      if (result.status === "success") {
        this.setState({
          counters: result.data.counters
        })
      } else {
        alert("fail: " + result.message)
      }
    } catch(error) {
      console.log(error)
    }
  }

  async addCase(caseObj) {
    try {
      var result = await postReq("case/add", caseObj)
      if (result.status === "success") {
        await this.loadCounters()
        this.setState({caseID: result.data.case.id})
      } else {
        alert("fail: " + result.message)
      }
    } catch (error) {
      console.log(error)
    }
  }
  
  render() {
    let {counters, caseID} = this.state
    if (!counters) return (<div>Loading...</div>)
    if (caseID) return (
      <div className="mt-5">
        Added case. Go to
        <Link to={"/case/get/" + caseID}> {caseID}</Link>
      </div>
    )
    
    return (
      <div>
        <div className="mt-5">
          <h1>Add case</h1>
        </div>
        <AddCaseForm counters={counters} addCase={this.addCase}/>
      </div>
    )
  }
}