import React from "react";
import {Button, ButtonGroup} from "react-bootstrap"
import { Link } from "react-router-dom";
import { displayDate } from "../common/common";

/**
 * Expects props:
 * - item: Agenda item
 * - handleEdit
 * - handleRemove
 */
export default class AgendaRow extends React.Component {
  render() {
    let item = this.props.item
    let remList = item.remarks.map((r, i) => <li key={i}>{r}</li>);

    let btnGroup =
      <ButtonGroup aria-label="ListingAction">
        <Button
          variant="outline-dark" size="sm"
          disabled={!item.isActive}
          onClick={this.props.showEdit}
        >
          Edit
        </Button>
        <Button
          variant="outline-danger" size="sm"
          disabled={!item.isActive}
          onClick={this.props.showRemove}
        >
          Remove
        </Button>
      </ButtonGroup>

    return (
      <tr>
        <td><Link to={"/case/get/" + item.id}>{item.id}</Link></td>
        <td>{item.title}<span className="text-danger">{item.isActive ? "": " [Disposed]"}</span> </td>
        <td>{item.caseNum}</td>
        <td>{item.courtNum}</td>
        <td>{item.itemNum}</td>
        <td><ul className="list-unstyled">{remList}</ul></td>
        <td>
          {
            item.nextDate ? (
              <Link to={"/causelist/" + item.nextDate}>{displayDate(item.nextDate)}</Link>
            ) :
            (
              <Button
                variant="outline-dark" size="sm"
                onClick={this.props.showAddListing}
              >
                Update 
              </Button>
            )
          }
        </td>
        <td>{btnGroup}</td>
      </tr>
    );
  }
}