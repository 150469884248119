import React from "react";
import {Form} from "react-bootstrap"

/**
 * Props:
 * - fieldName:
 * - errors
 * - touched
 * - handleChange
 * - values
 * 
 * Returns Form.Control element
 */
export const FormikTextField = (props) => {
  const {fieldName, errors, touched, handleChange, values, ...moreProps} = props;

  return (
    <Form.Control
      type="text"
      name={fieldName}
      value={values[fieldName]}
      onChange={handleChange}
      isInvalid={touched[fieldName] && errors[fieldName]}
      {...moreProps}
    />
  )
}

/**
 * Props:
 * - fieldName:
 * - options: Choices of form [["label", "value"], ...]
 * - errors
 * - touched
 * - handleChange
 * - values
 * 
 * Returns Form.Check elements (unwrapped)
 */
export const FormikRadioGroup = (props) => {
  const {fieldName, options, errors, touched, handleChange, values} = props
  
  return (
    <>
      {
        options.map(([label, value]) => (
          <Form.Check
            checked={value === values[fieldName]}
            inline
            label={label}
            type="radio"
            name={fieldName}
            value={value}
            onChange={handleChange}
            key={value}
          />
        ))
      }
    </>
  )
}

/**
 * Props:
 * - fieldName:
 * - options: Choices of form [["label", "value"], ...]
 * - errors
 * - touched
 * - handleChange
 * - values
 * 
 * Returns Form.Check elements (unwrapped)
 */
export const FormikSelect = (props) => {
  const {fieldName, options, errors, touched, handleChange, values} = props
  
  return (
    <Form.Control
      as="select"
      onChange={handleChange}
      name={fieldName}
      value={values[fieldName]}
      isInvalid={touched[fieldName] && errors[fieldName]}
    >
      <option disabled value="">Select...</option>
      {
        options.map(([label, value]) => (
          <option
            key={value}
            value={value}
          >{label}</option>
        ))
      }
    </Form.Control>
  )
}