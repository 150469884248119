import React from "react"
import { Table } from "react-bootstrap"
import { getReq } from "../common/requests"

export default class CounterPanel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      counters: []
    }
  }

  async componentDidMount() {
    try {
      let res = await getReq("counter/find")
      if (res.status === "success") {
        this.setState({loaded: true, counters: res.data.counters})
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    if (!this.state.loaded) return (<div>Loading...</div>)
    return (
      <div>
        <div><h4>Case count</h4></div>
        <Table responsive>
          <thead>
            <tr>
              <th>Court</th>
              <th>No. of cases</th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.counters.map(c => (
                <tr key={c.court}>
                  <td>{c.court}</td>
                  <td>{c.count}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>

      </div>
    )
  }
}