import { ButtonGroup, Button } from "react-bootstrap";
import { Link } from "react-router-dom"
import { displayDate } from "../common/common";
/**
 * Expects props:
 * - lst: Listing object
 * - isActive: Case status
 * - showEdit: called without args
 * - showRemove: called without args
 */
export default function ListingRow(props) {

  let rowClass = props.isActive ?
    (
      (props.lst.isUpcoming ? "table-warning" : "table-default")
    ) : "table-secondary";

  let remList = props.lst.remarks.map((r, i) => <li key={i}>{r}</li>);

  let btnGroup = (
    <ButtonGroup aria-label="ListingAction">
      <Button
        variant="outline-dark" size="sm"
        disabled={!props.isActive}
        onClick={props.showEdit}
      >
        Edit
      </Button>
      <Button
        variant="outline-danger" size="sm"
        disabled={!props.isActive}
        onClick={props.showRemove}
      >
        Remove
      </Button>
    </ButtonGroup>
  )

  return (
    <tr className={rowClass}>
      <td>
        <Link to={"/causelist/" + props.lst.date}>
          {displayDate(props.lst.date)}
        </Link>
      </td>
      <td>{props.lst.courtNum}</td>
      <td>{props.lst.itemNum}</td>
      <td><ul className="list-unstyled">{remList}</ul></td>
      <td>{btnGroup}</td>
    </tr>
  );
}