const BASE_URL = "/api/"

export async function getReq (path, params) {
  console.log("GET: %o, %o", path, params)
  if (params && Object.keys(params).length > 0) {
    path += "?";
    for (const k of Object.keys(params)) {
      path += `${k}=${encodeURIComponent(params[k])}`;
      path += "&";
    }
    path = path.slice(0, -1);
  }
  var res = await fetch(BASE_URL + path)
  if (res.status === 200) {
    return await res.json()
  }
  throw new Error(res.statusText)
}

export async function postReq (path, data) {
  console.log("POST: %o, %o", path, data)
  let res = await fetch(BASE_URL + path, {
    method: "POST",
    headers: {
      "Content-Type" : "application/json"
    },
    body: JSON.stringify(data)
  })
  if (res.status === 200) return await res.json()
  throw new Error(res.statusText)
}