import React, { useState } from "react";
import { postReq } from "../common/requests";
import DatePickerForm from "../common/date-picker-form";
import moment from "moment";
import { displayDate, ConfirmModal } from "../common/common";

/**
 * Props:
 * - caseObj
 * - update 
 */
export default function AddListingPanel(props) {

  const [date, setDate] = useState("")
  const [adding, setAdding] = useState(false)

  async function addListing() {
    let body = {
      id: props.caseObj.id,
      date: date,
      courtNum: "",
      itemNum: "",
      remarks: []
    }
    let res = await postReq("case/listing/add", body)
    if (res.status === "success") {
      props.update(res.data.case)
    } else {
      alert("fail: " + res.message)
    }
    setAdding(false)
  }

  return (
    <div>
      <DatePickerForm 
      onSubmit={(date) => {setDate(date); setAdding(true)}}
      buttonText="Add Listing" 
      initialDate={new Date()}
      isValid={(date) => {
        if (!props.caseObj.isActive) return {valid: false, message: "Case is disposed"}
        if (!date) return {valid: false, message: "Date is required"}
        if (!moment(date).isValid) return {valid: false, message: "Please enter a valid date"}
        let d = moment(date).format("YYYY-MM-DD")
        let index = props.caseObj.listings.findIndex(l => l.date === d)
        if (index !== -1) return {valid: false, message: "Listing already exists"}
        return {valid: true}
      }}
      />
      <ConfirmModal
        show={adding}
        onClose={() => setAdding(false)}
        onConfirm={addListing}
        heading={"Confirm add listing"}
        body={"Do you really want to add listing for " + displayDate(date)}
        actionName="Add listing"
      />
    </div>
  );
}