import moment from 'moment';
import React from 'react';
import { Button, Modal } from "react-bootstrap"

/**
 * Props:
 * - show: visibility of modal
 * - onClose
 * - onConfirm
 * - heading
 * - body
 * - actionName
 */
export const ConfirmModal = (props) => (
  <div>
    <Modal show={props.show} onHide={props.onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{props.heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={props.onConfirm}>
          {props.actionName}
        </Button>
      </Modal.Footer>
    </Modal>
  </div>
)

export const displayDate = (date) => {
  return moment(date).format("DD/MM/YYYY")
}

export const escapeRegex = s => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')