import React from "react";
import { Row, Col } from "react-bootstrap";
import { getReq } from "../common/requests";
import CaseTaskPanel from "./case-task-panel";
import CounterPanel from "./counts";
import MiscTaskPanel from "./misc-task-panel";

export default class HomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tasks: []
    }
    this.updateTasks = this.updateTasks.bind(this)
  }

  async componentDidMount() {
    try {
      let res = await getReq("task/find")
      if (res.status === "success") {
        this.setState({ tasks: res.data.tasks })
      } else {
        alert(res.message)
      }
    } catch (error) {
      console.log(error)
    }
  }

  updateTasks(tasks) {
    this.setState({tasks: tasks})
  }

  render() {
    return (
      <div>
        <Row className="mt-5">
          <Col>
            <CaseTaskPanel
              tasks={this.state.tasks.filter(t => t.caseID !== null)} 
              updateTasks={this.updateTasks} 
            />
          </Col>
          <Col>
            <MiscTaskPanel
              tasks={this.state.tasks.filter(t => t.caseID === null)}
              updateTasks={this.updateTasks} 
            />
          </Col>
          <Col>
            <CounterPanel />
          </Col>
        </Row>
      </div>
    )
  }
}
