import React from "react";

import { Modal, Button, Form, FormGroup, FormLabel } from "react-bootstrap"
import { postReq } from "../common/requests";
import { FormikTextField} from "../common/form-utils";
import * as yup from "yup"
import { Formik } from "formik"

const miscTaskSchema = yup.object.apply().shape({
  desc: yup.string().required(),
})

const FormCreator = (props) => {
  const { errors, touched, handleSubmit, handleChange, values } = props.formikProps
  const args = {errors, touched, handleChange, values}
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Form noValidate onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Add a task</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <FormGroup>
            <FormLabel>Description</FormLabel>
            <FormikTextField fieldName="desc" as="textarea" {...args} />
          </FormGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => props.setShow(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Add
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

/**
 * Props:
 * - show
 * - setShow: should control `show`
 * - update: calls it with (tasks)
 */
export default function MiscTaskAddForm(props) {
  return (
    <Formik
      initialValues={{
        desc: ""
      }}
      validationSchema={miscTaskSchema}
      onSubmit={async (values, { resetForm }) => {
        let res = await postReq("task/add", {
          desc: values.desc,
          caseID: null
        })
        if (res.status === "success") {
          props.update(res.data.tasks)
          props.setShow(false)
          resetForm()
        } else {
          alert(res.message)
        }
      }}
    >
      {(formikProps) => (<FormCreator formikProps={formikProps} {...props} />)}
    </Formik>
  )
}