import { Form, Row, Col } from "react-bootstrap"
import { useState } from "react"
import { escapeRegex } from "./common"

/**
 * Props:
 * - cases 
 * - onChange
 */
export default function MiniCaseSearchPanel (props) {
  let [searchTerm, setSearchTerm] = useState("")
  let [filteredCases, setFilteredCases] = useState([])

  return (
    <Row>
      <Col>
        <Form.Label>Search for case</Form.Label>
        <Form.Control
          placeholder="Enter file no. or title" 
          value={searchTerm}
          onChange={
            (e) => {
              let v = e.target.value
              setSearchTerm(v)
              let ev = escapeRegex(v)
              setFilteredCases(props.cases.filter(c => {
                return c.id.match(new RegExp(`^${ev}|${ev}$`, "i"))
                || c.title.match(new RegExp(`${ev}`, "i"))
              }))
            }
          }
        />
      </Col>
      <Col>
        <Form.Select htmlSize="4" onChange={(e) => props.onChange(e.target.value)}>
          {
            filteredCases.map(c => (
              <option value={c.id} key={c.id}>
                {c.id + ": " + c.title}
              </option>
            ))
          }
        </Form.Select>
      </Col>
    </Row>
  )
}