import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"

/**
 * Expects Props:
 * - task: case task
 * - remove: call w/o args
 * - toggle: call w/o args
 */
export default function CaseTaskRow(props) {
  let t = props.task
  let rowClass = t.isDone ? "table-success" : "table-default"

  return (
    <tr className={rowClass}>
      <td><Link to={"/case/get/" + t.caseID}>{t.caseID}</Link></td>
      <td>{t.desc}</td>
      <td>
        <Button
          variant={"outline-" + (t.isDone ? "dark" : "success")}
          size="sm"
          onClick={props.toggle}
        >{t.isDone ? "Undo" : "Done"}</Button>
      </td>
      <td>
        <Button
          variant="outline-danger"
          size="sm"
          onClick={props.remove}
        >Delete</Button>
      </td>
    </tr>
  )
}