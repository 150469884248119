import React from "react";
import moment from "moment";
import {Form, Button, Row, Col} from "react-bootstrap"
import { Formik } from "formik"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

/**
 * DatePicker props:
 * - name
 * - selected
 * - onChange
 * - dateFormat
 */


/**
 * Create a form (under formik)
 */
const FormCreator = (props) => {
  const { errors, touched, handleSubmit, setFieldValue, values } = props.formikProps
  return (
    <Form noValidate onSubmit={handleSubmit}>
      <Form.Group as={Row}>
        <Col>
          <DatePicker
            className={"form-control" + (touched.date && errors.date ? " is-invalid" : "")}
            name="date"
            selected={values.date}
            onChange={val => setFieldValue("date", val)}
            dateFormat="dd/MM/yyyy"
          />
          <div className="d-block invalid-feedback">
            {errors.date}
          </div>
        </Col>
        <Col>
          <Button type="submit">{props.buttonText}</Button>
        </Col>
      </Form.Group>
    </Form>
  )
}
/**
 * Expected props:
 * - onSubmit: (date) => {}
 * - initialDate
 * - buttonText
 * - isValid: validator function (date) => {valid: bool, message:""}
 */
const DatePickerForm = (props) => (
  <div>
    <Formik
      initialValues={{
        date: props.initialDate ? new Date(props.initialDate) : null
      }}
      validate = {(values) => {
        let errors = {}
        let res = props.isValid(values.date)
        if (!res.valid) errors.date = res.message
        return errors
      }}
      onSubmit={async(values) => {
        let date = moment(values.date).format("YYYY-MM-DD")
        props.onSubmit(date)
      }}
    >{(formikProps) => <FormCreator formikProps={formikProps} buttonText={props.buttonText}/>}</Formik>
  </div>
)

export default DatePickerForm